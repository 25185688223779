@font-face {
  font-family: 'YourFontName'; /* Give your font a name */
  src: url('./fonts/Montserrat-VariableFont_wght.ttf') format('ttf'); /* Adjust the file path and format */
  /* Add additional src lines for other file formats if needed */
}

body {
  margin: 0;
  padding: 0;
  background-image: url('./back.jpg');
  background-size: cover;
  background-attachment: fixed;
  font-family: 'YourFontName', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-size: cover;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin-top: 20px;
  margin-right: 30px; /* Add spacing between the text and the logo */
}

.header-text {
  color: black;
  text-align: center;
  margin-top: 30px;
  margin-right: 20px; /* Add spacing between the text and the links */
}

.header-text h1 {
  font-size: calc(30px + 2vmin); /*UniSafe*/
  margin: 0;
}

nav {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

nav a {
  color: black;
  text-decoration: none;
  margin-left: 20px; /* Add spacing between links */
}

nav a:hover {
  text-decoration: underline;
}

.body-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
}

.left-content,
.right-content {
  flex: 1; /* Make both left and right content flexible */
}

.left-content {
  text-align: left;
  font-size: calc(5px + 2vmin);
  margin-left: 30px;
  margin-top: 20px;
}

.right-content {
  margin-left: 20px; /* Add some space between left and right content */
}

.right-content img {
  width: 100%;
}

/* Media query for smaller screens (max-width: 767px) */
@media (max-width: 1300px) {
  .body-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
  }

  .right-content{
    flex: none; /* Reset flex property for individual width control */
    width: 100%; /* Take up full width on smaller screens */
    margin-bottom: 5px; /* Adjust spacing for smaller screens */
    margin-left: 0;
    order: 1;
    color: black;
  }
  .left-content {
    flex: none; /* Reset flex property for individual width control */
    width: 100%; /* Take up full width on smaller screens */
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
    margin-left: 0;
    order: 2;
    color: black;
  }
}

.line-with-text {
  text-align: center;
  margin: 10px auto; /* Adjust the margin as needed */
}

.line-with-text h2 {
  font-size: calc(30px + 2vmin); /* Adjust the font size as needed */
  color: #2E4A36; /* Adjust the text color as needed */
}
@media (max-width: 1300px) {
  .line-with-text h2 {
    font-size: calc(20px + 2vmin);
  }
  .last-text {
    font-size: calc(2px + 2vmin);
  }
  .App-header {
    background-size: cover;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(3px + 2vmin);
    color: white;
  }
  .App-logo {
    height: 10vmin;
    pointer-events: none;
    margin-top: 25px;
    margin-right: 5px; /* Add spacing between the text and the logo */
  }
  .header-text h1 {
    font-size: calc(10px + 2vmin); /*UniSafe*/
    margin: 0;
  }
  .header-text {
    color: black;
    text-align: center;
    margin-top: 30px;
    margin-right: 0px; /* Add spacing between the text and the links */
  }
}
.left-content a {
  color: black; /* Set the link color to black */
  text-decoration: underline; /* Add underline to the link */
}
.line-after-text {
  content: '';
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 30px;
  height: 4px; /* Adjust as needed */
  background-color: #D6EAD6;
  transform: translate(0, 50%);
}